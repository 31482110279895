<template>
  <v-app>
    <v-card v-if="isPublicPage()" class="c-questionario">
      <v-main>
        <v-container fluid class="appvue">
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-card>
    <v-footer class="py-6 px-0 white--text" color="#06457F">
      <v-container class="wrapper" fluid>
        <v-col>
          <p>
            Puoi consultare l'informativa privacy qui:
            <a
              class="primary--text font-weight-bold text-decoration-none"
              href="https://orientamento2026-backend.cineca.it/uploads/2024/orientamento2026-pubblico/Informativa_questionario.pdf"
              target="_blank"
            >
              Privacy</a
            >
          </p>
        </v-col>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "app",
  components: {},
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    isPublicPage() {
      const route = this.$route;
      return (
        route.path.includes("compilazione") &&
        route.path.includes("questionari")
      );
    },
  },
};
</script>

<style scoped>
.appvue {
  background: radial-gradient(
    58.98% 58.98% at 50.02% 50.02%,
    #44bfe5 0%,
    #035bb2 100%
  );
}

.wrapper {
  margin: 0 auto;
  width: 76%;
  max-width: 1200px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.formDomandeQuestionario && _vm.studentiCorsoGrouped)?_c('v-card',[_c('v-form',{ref:"formQuestioanrio",staticClass:"ma-4"},[_vm._l((_vm.domandeQuestionario),function(domanda,index){return _c('div',{key:index,staticClass:"question ma-2 pa-2"},[(/*isDomandaVisualizzabile(index)*/ true)?_c('v-card-text',[_c('h2',[_vm._v(" "+_vm._s(domanda.domandaPadre.testoDomanda)+" ")]),(domanda.domandaPadre.note)?_c('v-card-text',[_vm._v(" "+_vm._s(domanda.domandaPadre.note)+" ")]):_vm._e(),(domanda.sottoDomande.length)?_c('v-card-text',_vm._l((domanda.sottoDomande),function(sottoDomanda,indexSottoDomanda){return _c('v-radio-group',{key:indexSottoDomanda,attrs:{"label":sottoDomanda.testoDomanda},model:{value:(
              _vm.formDomandeQuestionario[index].sottoDomande[indexSottoDomanda]
                .rispostaStudente
            ),callback:function ($$v) {_vm.$set(_vm.formDomandeQuestionario[index].sottoDomande[indexSottoDomanda]
                , "rispostaStudente", $$v)},expression:"\n              formDomandeQuestionario[index].sottoDomande[indexSottoDomanda]\n                .rispostaStudente\n            "}},_vm._l((_vm.getItemsSelectInput(
                sottoDomanda.id,
                sottoDomanda.opzioniRisposteDomanda
              )),function(itemRadio,indexRadio){return _c('v-radio',{key:indexRadio,attrs:{"label":itemRadio.text,"value":itemRadio.value}})}),1)}),1):_c('v-card-text',[(domanda.domandaPadre.tipoDomanda === 'radiobutton')?_c('v-radio-group',{model:{value:(_vm.formDomandeQuestionario[index].rispostaStudente),callback:function ($$v) {_vm.$set(_vm.formDomandeQuestionario[index], "rispostaStudente", $$v)},expression:"formDomandeQuestionario[index].rispostaStudente"}},_vm._l((_vm.getItemsSelectInput(
                domanda.domandaPadre.id,
                domanda.domandaPadre.opzioniRisposteDomanda
              )),function(itemRadio,indexRadio){return _c('v-radio',{key:indexRadio,attrs:{"label":itemRadio.text,"value":itemRadio.value}})}),1):(domanda.domandaPadre.tipoDomanda === 'select')?_c('v-autocomplete',{attrs:{"items":_vm.getItemsSelectInput(
                domanda.domandaPadre.id,
                domanda.domandaPadre.opzioniRisposteDomanda
              ),"item-text":"text","item-value":"value"},model:{value:(_vm.formDomandeQuestionario[index].rispostaStudente),callback:function ($$v) {_vm.$set(_vm.formDomandeQuestionario[index], "rispostaStudente", $$v)},expression:"formDomandeQuestionario[index].rispostaStudente"}}):_c('v-textarea',{attrs:{"outlined":"","name":"input-7-1","filled":"","counter":"1000"},model:{value:(_vm.formDomandeQuestionario[index].rispostaStudente),callback:function ($$v) {_vm.$set(_vm.formDomandeQuestionario[index], "rispostaStudente", $$v)},expression:"formDomandeQuestionario[index].rispostaStudente"}})],1),_c('v-divider',{staticClass:"mb-4 mt-4"})],1):_vm._e()],1)}),_c('v-btn',{staticClass:"pa-2 ma-8 mt-0 white--text",attrs:{"color":"primary","disabled":_vm.operazioneInCorso},on:{"click":_vm.onSubmitQuestionario}},[_vm._v(" Invia ")])],2)],1):_c('v-main',[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" Pagina in caricamento. "),_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
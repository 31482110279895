import Vue from "vue";
import VueRouter from "vue-router";
import CompilazioneQuestionarioView from "@/views/Questionari/CompilazioneQuestionarioView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/questionari/:idCorso/compilazione",
    name: "compilazione-questionario",
    component: CompilazioneQuestionarioView,
    props: (route) => ({
      idCorso: parseInt(route.params.idCorso),
      datescad: route.query.datescad,
    }),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

/*router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  console.log(requiresAuth);

  if (!requiresAuth) {
    // Rotta richiede autenticazione e l'utente non è autenticato
    next();
  }
});*/

export default router;

import Repository from "@/api/Repository";

export default {
  async getProvince() {
    const url = `api/public/lista-province`;
    return (await Repository.get(url)).data;
  },
  async verificaCreazioneLinkQuestionarioCorso(idCorso) {
    const url = `api/public/questionari/${idCorso}/link-questionario`;
    return (await Repository.get(url)).data;
  },
  async getDomandeQuestionariPerStudenti() {
    const url = `api/public/questionari/tipologie-domande`;
    return (await Repository.get(url)).data;
  },
  async invioQuestionarioStudente(idCorso, formBody) {
    const url = `api/public/questionari/corso/${idCorso}/compilazioni`;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return (await Repository.post(url, formBody, { headers })).data;
  },
  async getClassiStudente() {
    const url = `api/public/classi-studente`;
    return (await Repository.get(url)).data;
  },
  async getGeneriStudente() {
    const url = `api/public/generi-studente`;
    return (await Repository.get(url)).data;
  },
  async countStudentiCorsoGroupedByGenere(idCorso) {
    const url = `api/public/questionari/corso/${idCorso}/count-studenti-raggruppati`;
    return (await Repository.get(url)).data;
  },
};

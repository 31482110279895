<template>
  <v-container>
    <v-card
      class="container-questionario white--text mb-8 mt-16"
      color="primary"
      elevation="5"
      v-if="loadingPageCompilazioneQuestionario"
    >
      <v-card-title class="card-title">
        <h3 class="ps-8 pt-8">Compilazione questionario</h3></v-card-title
      >
      <v-card-text class="card-text">
        <p class="ps-8 white--text">
          Il questionario è completamente anonimo; ti garantiamo dunque che non
          sarà possibile in alcun modo risalire alla tua identità.
        </p>
      </v-card-text>
      <FormQuestionario
        @invio-questionario="inviaQuestionario"
        class="rounded-0 rounded-b pb-4"
      />
      <v-dialog
        :dialog.sync="dialogInvioQuestionario"
        v-model="dialogInvioQuestionario"
        persistent
      >
        <dialog-invio-questionario />
      </v-dialog>
      <v-snackbar :color="snackbar.color" v-model="snackbar.value">
        {{ snackbar.text }}
      </v-snackbar>
    </v-card>
    <v-main v-else>
      <v-alert type="error">
        {{ controlloVisualizzazionePagina.motivazione }}
      </v-alert>
    </v-main>
    <v-main>
      <v-row class="flex-wrap d-flex justify-space-between align-center my-8">
        <v-col class="col-md-4">
          <v-img
            max-width="100%"
            max-height="60px"
            contain
            :src="require('@/assets/logo-orientamento-2026.png')"
            alt="logo-orientamento-2026"
          />
        </v-col>
        <v-col class="col-md-4">
          <v-img
            max-width="100%"
            max-height="60px"
            contain
            :src="require('@/assets/logo-mur-social-white.png')"
            alt="Logo-mur-bianco"
          />
        </v-col>
        <v-col class="col-md-4">
          <v-img
            max-width="100%"
            max-height="60px"
            contain
            :src="require('@/assets/italiadomani-tracciato-bianco.png')"
            alt="Logo-Italia-Domani"
          />
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>
<script>
import FormQuestionario from "@/components/Questionari/FormQuestionario.vue";
import QuestionariRepository from "@/api/istituto/QuestionariRepository";
import DialogInvioQuestionario from "@/components/Questionari/DialogInvioQuestionario.vue";

export default {
  name: "compilazioneQuestionario",
  components: { DialogInvioQuestionario, FormQuestionario },
  computed: {},
  watch: {
    idCorso() {
      this.initialize();
    },
    datescad() {
      this.initialize();
    },
  },
  props: {
    idCorso: {
      type: [Number, String],
      required: true,
    },
    datescad: {
      type: [Number, String],
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  data: () => ({
    formRisposte: null,
    loadingPageCompilazioneQuestionario: false,
    controlloVisualizzazionePagina: {
      controllo: true,
      motivazione: "",
    },
    snackbar: {
      color: "",
      text: "",
      value: false,
    },
    dialogInvioQuestionario: false,
  }),
  methods: {
    initialize() {
      this.getLinkQuestionario();
    },
    checkVisualizzazionePagina() {
      const checkValidityParameters = this.checkValidityParameters();

      if (!checkValidityParameters.visualizzazione) {
        this.controlloVisualizzazionePagina.controllo =
          checkValidityParameters.visualizzazione;
        this.controlloVisualizzazionePagina.motivazione =
          checkValidityParameters.motivazione;
        return (this.loadingPageCompilazioneQuestionario = false);
      }
      if (!this.infoLinkQuestionario) {
        this.controlloVisualizzazionePagina.controllo = false;
        this.controlloVisualizzazionePagina.motivazione =
          " Il link per lo svolgimento del questionario non è stato ancora generato";
        return (this.loadingPageCompilazioneQuestionario = false);
      } else {
        this.controlloVisualizzazionePagina.controllo = true;
        return (this.loadingPageCompilazioneQuestionario = true);
      }
    },
    checkValidityParameters() {
      // parmetri idCorso e data scadenza link non specificati o invalidi
      if (
        !this.$route.query.datescad ||
        !this.idCorso ||
        parseInt(this.idCorso) < 1
      ) {
        return {
          visualizzazione: false,
          motivazione:
            "Link non valido. Parametro/i non specificati o invalidi",
        };
      } else {
        const dataScadenzaLInk = new Date(this.$route.query.datescad * 1000);
        const dateOdierna = new Date();

        if (dateOdierna > dataScadenzaLInk) {
          return {
            visualizzazione: false,
            motivazione:
              "Il link generato per la compilazione dei questionari è scaduto.",
          };
        } else {
          return {
            visualizzazione: true,
            motivazione: "",
          };
        }
      }
    },
    async getLinkQuestionario() {
      const idCorso = parseInt(this.idCorso);
      this.infoLinkQuestionario =
        await QuestionariRepository.verificaCreazioneLinkQuestionarioCorso(
          idCorso
        );

      this.checkVisualizzazionePagina();
    },
    inviaQuestionario: async function (formBody) {
      const idCorso = parseInt(this.idCorso);
      await QuestionariRepository.invioQuestionarioStudente(idCorso, formBody)
        .then(() => {
          // this.setValueSnackbar(data[0], "success");
          this.dialogInvioQuestionario = true;
        })
        .catch((reason) => {
          if (reason.response.status === 500) {
            this.setValueSnackbar(
              "E' stato riscontrato un'errore generico nell'invio dei dati del questionario",
              "red accent-2"
            );
          }
          this.operazioneInCorso = false;
        });
    },
    setValueSnackbar(text, color) {
      this.snackbar.color = color;
      this.snackbar.text = text;
      this.snackbar.value = true;
    },
  },
};
</script>

<template>
  <v-card elevation="5" style="text-align: center">
    <v-card-text>
      Ti ringraziamo per le risposte che ci hai fornito, <br />
      che ci aiuteranno a migliorare i contenuti e l’organizzazione di questi
      corsi di orientamento. <br />
      <br />

      <v-icon color="success" style="width: 20%; height: 20%">
        mdi-check-circle-outline</v-icon
      >
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "dialogInvioQuestionario",
  components: {},
  data: () => ({}),
  methods: {},
};
</script>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLogger from "vuejs-logger";
import vuetify from "@/plugins/vuetify";
import filtersNumber from "@/filters/filtersNumber";
import filtersDate from "@/filters/filtersDate";

filtersNumber.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

filtersDate.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

const isProduction = process.env.NODE_ENV === "production";

Vue.config.productionTip = false;

const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.use(VueLogger, loggerOptions);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

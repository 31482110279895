<template>
  <v-card v-if="formDomandeQuestionario && studentiCorsoGrouped">
    <v-form ref="formQuestioanrio" class="ma-4">
      <div
        class="question ma-2 pa-2"
        v-for="(domanda, index) in domandeQuestionario"
        :key="index"
      >
        <v-card-text v-if="/*isDomandaVisualizzabile(index)*/ true">
          <h2>
            {{ domanda.domandaPadre.testoDomanda }}
          </h2>

          <v-card-text v-if="domanda.domandaPadre.note">
            {{ domanda.domandaPadre.note }}
          </v-card-text>

          <v-card-text v-if="domanda.sottoDomande.length">
            <v-radio-group
              v-for="(sottoDomanda, indexSottoDomanda) in domanda.sottoDomande"
              :key="indexSottoDomanda"
              :label="sottoDomanda.testoDomanda"
              v-model="
                formDomandeQuestionario[index].sottoDomande[indexSottoDomanda]
                  .rispostaStudente
              "
            >
              <v-radio
                v-for="(itemRadio, indexRadio) in getItemsSelectInput(
                  sottoDomanda.id,
                  sottoDomanda.opzioniRisposteDomanda
                )"
                :key="indexRadio"
                :label="itemRadio.text"
                :value="itemRadio.value"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-text v-else>
            <v-radio-group
              v-model="formDomandeQuestionario[index].rispostaStudente"
              v-if="domanda.domandaPadre.tipoDomanda === 'radiobutton'"
            >
              <v-radio
                v-for="(itemRadio, indexRadio) in getItemsSelectInput(
                  domanda.domandaPadre.id,
                  domanda.domandaPadre.opzioniRisposteDomanda
                )"
                :key="indexRadio"
                :label="itemRadio.text"
                :value="itemRadio.value"
              ></v-radio>
            </v-radio-group>
            <v-autocomplete
              v-else-if="domanda.domandaPadre.tipoDomanda === 'select'"
              :items="
                getItemsSelectInput(
                  domanda.domandaPadre.id,
                  domanda.domandaPadre.opzioniRisposteDomanda
                )
              "
              item-text="text"
              item-value="value"
              v-model="formDomandeQuestionario[index].rispostaStudente"
            />
            <v-textarea
              v-else
              v-model="formDomandeQuestionario[index].rispostaStudente"
              outlined
              name="input-7-1"
              filled
              counter="1000"
            ></v-textarea>
          </v-card-text>
          <v-divider class="mb-4 mt-4"></v-divider>
        </v-card-text>
      </div>
      <v-btn
        color="primary"
        class="pa-2 ma-8 mt-0 white--text"
        @click="onSubmitQuestionario"
        :disabled="operazioneInCorso"
      >
        Invia
      </v-btn>
    </v-form>
  </v-card>
  <v-main v-else>
    <v-alert type="warning">
      Pagina in caricamento.
      <v-progress-circular indeterminate color="primary" />
    </v-alert>
  </v-main>
</template>
<script>
import QuestionariRepository from "@/api/istituto/QuestionariRepository";

export default {
  name: "formQuestionario",
  components: {},
  data: () => ({
    domandeQuestionario: [],
    classiStudente: null,
    generiStudente: null,
    formDomandeQuestionario: {},
    province: [],
    operazioneInCorso: false,
    colonneDomandeMultiple: [
      "del tutto",
      "molto",
      "abbastanza",
      "poco",
      "per niente",
      "non risponde",
    ],
    studentiCorsoGrouped: null,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getDomandeQuestionari();
      this.getClassiStudente();
      this.getGeneriStudente();
      this.getProvince();
      this.countStudentiCorsoGroupedByGenere(this.$route.params.idCorso);
      this.loadingPage = true;
    },

    async getDomandeQuestionari() {
      this.domandeQuestionario =
        await QuestionariRepository.getDomandeQuestionariPerStudenti();
      if (this.domandeQuestionario) {
        this.creaForm();
      }
    },
    async getClassiStudente() {
      const classi = await QuestionariRepository.getClassiStudente();
      this.classiStudente = classi.map((element) => {
        return {
          text: element.descrizione,
          value: element.id + " - " + element.descrizione,
        };
      });
    },
    async getProvince() {
      this.province = await QuestionariRepository.getProvince();
    },
    async getGeneriStudente() {
      const generi = await QuestionariRepository.getGeneriStudente();
      this.generiStudente = generi.map((element) => {
        return {
          text: element.descrizione,
          value: element.id + " - " + element.descrizione,
        };
      });
      this.generiStudente.push({
        text: "Preferisco non rispondere",
        value: null,
      });
    },
    getItemsSelectInput(idDomanda, opzioniRisposta) {
      switch (idDomanda) {
        case 1:
          return this.generiStudente;
        case 2:
          return this.province;

        case 4:
          return this.classiStudente;

        default:
          return opzioniRisposta.map((element) => {
            return {
              text: element.opzioneRispostaDomandaQuestionario.testo,
              value:
                element.opzioneRispostaDomandaQuestionario.valore +
                " - " +
                element.opzioneRispostaDomandaQuestionario.testo,
            };
          });
      }
    },
    /** creo l'oggetto form in cui andrò a meorizzare i dati delle domande e le opzioni di risposta, ma anche la risposta dello studente */
    creaForm() {
      let arrForm = [];
      arrForm = this.domandeQuestionario.map((element) => {
        let objForm = element;
        if (!element.sottoDomande.length) {
          objForm.rispostaStudente = null;
        } else {
          objForm.sottoDomande.map((sottoDomande) => {
            sottoDomande.rispostaStudente = null;
            return sottoDomande;
          });
        }
        return objForm;
      });
      this.formDomandeQuestionario = arrForm;
    },
    onSubmitQuestionario() {
      this.operazioneInCorso = true;
      const formBody = new FormData();
      const datiQuestionario = JSON.stringify(this.formDomandeQuestionario);
      formBody.append("questionarioStudente", datiQuestionario);
      this.$emit("invio-questionario", formBody);
    },
    async countStudentiCorsoGroupedByGenere(idCorso) {
      this.studentiCorsoGrouped =
        await QuestionariRepository.countStudentiCorsoGroupedByGenere(idCorso);
    },
    isDomandaVisualizzabile(indexDomanda) {
      let keys = [];
      let conteggiStudente = this.studentiCorsoGrouped;
      // la domanda è visualizzabile se e solo se, per ogni chiave di raggruppamento, il conteggio degli studenti del corso deve essere almeno 3
      // oppure se gli studenti appartengono esclusivamente ad una chiave di raggruppamento stabilita
      switch (indexDomanda) {
        case 0:
          // chiavi di raggruppamento sono il genere dei studenti
          keys = Object.keys(conteggiStudente.countStudentiPerGenere);
          return (
            keys.length === 1 ||
            this.controlloNumStudenti("countStudentiPerGenere", keys)
          );
        case 1:
          keys = Object.keys(conteggiStudente.countStudentiPerProvincia);
          return (
            keys.length === 1 ||
            this.controlloNumStudenti("countStudentiPerProvincia", keys)
          );
        case 2:
          // chiavi di raggruppamento sono i codici scuola che gli studenti frequentano
          keys = Object.keys(conteggiStudente.countStudentiPerScuola);
          return (
            keys.length === 1 ||
            this.controlloNumStudenti("countStudentiPerScuola", keys)
          );

        case 3:
          // chiavi di raggruppamento sono le classi ( III, IV V, II scuole biennali) a cui appartengono gli studenti
          keys = Object.keys(conteggiStudente.countStudentiPerClasse);
          return (
            keys.length === 1 ||
            this.controlloNumStudenti("countStudentiPerClasse", keys)
          );

        default:
          return true;
      }
    },
    controlloNumStudenti(parentKey, childrenKeys) {
      let check = true;
      let i = 0;
      // per ogni chiave di raggrruppamento, verifico che il conteggio degli studenti sia almeno di 3
      while (i < childrenKeys.length) {
        let keyOfGroupment = childrenKeys[i];
        if (this.studentiCorsoGrouped[parentKey][keyOfGroupment] < 3) {
          check = false;
          break;
        }
        i++;
      }
      return check;
    },
  },
};
</script>
